html {
  position: relative;
  min-height: 100%;
  font-size: 1rem;
}

@media (max-width: 450px) {
  html {
    font-size: 1rem;
  }
  h1 {
    font-size: 0.9rem;
  }
  h2 {
    font-size: 0.8rem;
  }
  h3 {
    font-size: 0.8rem;
  }
  footer .container {
    font-size: 0.7rem;
    margin-bottom: 10rem;
  }
}

body {
  margin-bottom: 40px; /* Margin bottom by footer height */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #777777;
  font-weight: 500;
}

#searchPage a,
.featuredProduct a,
#catalogPage a,
#productListPage a {
  color: #595959;
  font-weight: bold;
}

#searchPage a:hover,
.featuredProduct a:hover,
#catalogPage a:hover,
#productListPage a:hover {
  color: #d14d21;
}

.footer a {
  color: #6c757d !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: fluid;
  width: 100%;
  height: 40px; /* Set the fixed height of the footer here */
  line-height: 40px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

main {
  padding: 5px;
}

#homePage .row {
  margin-bottom: 50px;
}

#heroImageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#cartPage .quantity {
  width: 50px;
}

#cartPage .row,
#reviewPage .row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.img-thumbnail {
  border: 0;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.searchImg {
  width: 80px; /* you can use % */
  height: auto;
}

#searchPage span {
  color: #6c757d !important;
}

.pageTitle,
#productName {
  color: #595959;
}
